var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg_col_fff bor_rad_8 box_sha_0_0_8_8_black_80_per p_16 m_0_16 m_t_16"},[_c('div',{staticClass:"dis_flex ali_it_cen"},[_c('div',{staticClass:"flex_1 font_16 font_bold"},[_vm._v("省长列表")]),_c('div',{staticClass:"dis_flex ali_it_cen"},[_c('div',{staticClass:"dis_flex ali_it_cen",on:{"click":function($event){_vm.filterForm = {
            ..._vm.filterForm,
            tempTimeStart: _vm.filterForm.tempTimeStart || new Date(),
            timeStep: 0,
            timeDialogShow: true,
          }}}},[_c('img',{staticClass:"w_16 h_16 dis_flex",attrs:{"src":"http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/TsvtFIIYzRzkBuSudCrx.png"}}),_c('div',{staticClass:"font_12 col_2396F5 m_l_4"},[_vm._v(" "+_vm._s(_vm.filterForm.timeStartStr || '开始日期')+" ")]),_c('div',{staticClass:"font_12 m_0_8"},[_vm._v("-")]),_c('div',{staticClass:"font_12 col_2396F5"},[_vm._v(" "+_vm._s(_vm.filterForm.timeEndStr || '结束日期')+" ")])]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterForm.timeStartStr),expression:"filterForm.timeStartStr"}],staticClass:"w_16 h_16 dis_flex m_l_4",attrs:{"src":"http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220705/sYOxRslRIFJplIZHjJAB.png"},on:{"click":function($event){_vm.filterForm = {};
          _vm.getList();}}})]),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.filterForm.timeDialogShow),callback:function ($$v) {_vm.$set(_vm.filterForm, "timeDialogShow", $$v)},expression:"filterForm.timeDialogShow"}},[(_vm.filterForm.timeStep == 0)?_c('van-datetime-picker',{key:"开始日期",attrs:{"type":"date","title":"开始日期"},on:{"confirm":function($event){_vm.filterForm = {
            ..._vm.filterForm,
            timeStep: 1,
          }},"cancel":function($event){_vm.filterForm = { ..._vm.filterForm, timeDialogShow: false }}},model:{value:(_vm.filterForm.tempTimeStart),callback:function ($$v) {_vm.$set(_vm.filterForm, "tempTimeStart", $$v)},expression:"filterForm.tempTimeStart"}}):_vm._e(),(_vm.filterForm.timeStep == 1)?_c('van-datetime-picker',{key:"结束日期",attrs:{"type":"date","title":"结束日期","min-date":_vm.filterForm.tempTimeStart},on:{"confirm":(v) => {
            _vm.filterForm = {
              ..._vm.filterForm,
              timeStart: _vm.moment(_vm.filterForm.tempTimeStart).format(
                'YYYY-MM-DD 00:00:00'
              ),
              timeStartStr: _vm.moment(_vm.filterForm.tempTimeStart).format(
                'YYYY-MM-DD'
              ),
              timeEnd: _vm.moment(v).format('YYYY-MM-DD 23:59:59'),
              timeEndStr: _vm.moment(v).format('YYYY-MM-DD'),
              timeDialogShow: false,
            };
            _vm.getList();
          },"cancel":function($event){_vm.filterForm = { ..._vm.filterForm, timeStep: 0 }}},model:{value:(_vm.filterForm.tempTimeEnd),callback:function ($$v) {_vm.$set(_vm.filterForm, "tempTimeEnd", $$v)},expression:"filterForm.tempTimeEnd"}}):_vm._e()],1)],1),_c('div',{staticClass:"m_t_16"},[_c('vxe-table',{attrs:{"loading":_vm.loading,"data":_vm.list,"border":"","resizable":"","align":"center"}},[_c('vxe-column',{attrs:{"title":`${
          ['省长', '合伙人', '经纪人'][_vm.currentBrokerArr.length]
        }名称`,"min-width":"112","fixed":"left"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.brokerName || '-')+" ")]}}])}),(_vm.currentBrokerArr.length < 1)?_c('vxe-column',{attrs:{"title":"合伙人数量","min-width":"96"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.columnAmount(row.leaderReigsterCount, { fixed: 0 }))+" ")]}}],null,false,1184318211)}):_vm._e(),_c('vxe-column',{attrs:{"title":"经纪人数量","min-width":"96"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.columnAmount(row.brokerReigsterCount, { fixed: 0 }))+" ")]}}])}),_c('vxe-column',{attrs:{"title":"新增经纪人","min-width":"96"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.columnAmount(row.newReigsterCount, { fixed: 0 }))+" ")]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }